// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-page-jsx": () => import("./../src/components/page.jsx" /* webpackChunkName: "component---src-components-page-jsx" */),
  "component---src-components-services-page-template-jsx": () => import("./../src/components/services-page-template.jsx" /* webpackChunkName: "component---src-components-services-page-template-jsx" */),
  "component---src-components-services-index-page-jsx": () => import("./../src/components/services-index-page.jsx" /* webpackChunkName: "component---src-components-services-index-page-jsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

